import React from 'react';
import { 
  Card, 
  CardContent, 
  Typography, 
  Chip, 
  Box, 
  Divider,
  List,
  ListItem,
  ListItemText,
  Grid
} from '@mui/material';
import WineBarIcon from '@mui/icons-material/WineBar';
import LocalDiningIcon from '@mui/icons-material/LocalDining';
import EuroIcon from '@mui/icons-material/Euro';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import ProductRecommendation from './ProductRecommendation';


const WineRecommendationCard = ({ recommendation }) => {
  const parsedRecommendation = typeof recommendation === 'string' 
    ? JSON.parse(recommendation) 
    : recommendation;

  const getPriceRangeColor = (priceRange) => {
    switch(priceRange.toLowerCase()) {
      case 'budget': return 'success';
      case 'moderate': return 'warning';
      case 'premium': return 'error';
      default: return 'default';
    }
  };

  const getWineTypeColor = (type) => {
    switch(type.toLowerCase()) {
      case 'red': return '#8B0000';
      case 'white': return '#F0E68C';
      case 'rosé': case 'rose': return '#FFB6C1';
      case 'sparkling': return '#FFD700';
      default: return '#000000';
    }
  };

  return (
    <Card sx={{ 
      bgcolor: 'rgba(255, 255, 255, 0.95)',
      backdropFilter: 'blur(10px)',
      borderRadius: 2,
      boxShadow: 3
    }}>
      <CardContent>
        <Box sx={{ mb: 3 }}>
          <Typography variant="h4" gutterBottom sx={{ 
            display: 'flex', 
            alignItems: 'center',
            gap: 1
          }}>
            <LocalDiningIcon /> {parsedRecommendation.foodItem}
          </Typography>
          {parsedRecommendation.cuisine && (
            <Typography variant="subtitle1" color="text.secondary">
              Cuisine: {parsedRecommendation.cuisine}
            </Typography>
          )}
          <Box sx={{ mt: 2, display: 'flex', flexWrap: 'wrap', gap: 1 }}>
            {parsedRecommendation.characteristics.map((char, index) => (
              <Chip key={index} label={char} variant="outlined" size="small" />
            ))}
          </Box>
        </Box>

        <Typography variant="body1" sx={{ mb: 2 }}>
          {parsedRecommendation.generalPairingNotes}
        </Typography>

        <Divider sx={{ my: 3 }} />

        <Grid container spacing={3}>
          {parsedRecommendation.wines.map((wine, index) => (
            <Grid item xs={12} md={6} key={index}>
              <Card variant="outlined" sx={{ height: '100%' }}>
                <CardContent>
                  <Typography variant="h6" sx={{ 
                    display: 'flex', 
                    alignItems: 'center',
                    gap: 1,
                    mb: 2
                  }}>
                    <WineBarIcon sx={{ color: getWineTypeColor(wine.type) }} />
                    {wine.name}
                  </Typography>
                  
                  <Box sx={{ display: 'flex', gap: 1, mb: 2 }}>
                    <Chip 
                      label={wine.type}
                      size="small"
                      sx={{ 
                        bgcolor: getWineTypeColor(wine.type),
                        color: wine.type.toLowerCase() === 'white' ? 'black' : 'white'
                      }}
                    />
                    <Chip 
                      icon={<AttachMoneyIcon />}
                      label={wine.priceRange}
                      size="small"
                      color={getPriceRangeColor(wine.priceRange)}
                    />
                  </Box>

                  <Typography variant="body2" sx={{ mb: 2 }}>
                    {wine.pairingReason}
                  </Typography>

                  <Typography variant="subtitle2" gutterBottom>
                    Characteristics:
                  </Typography>
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5, mb: 2 }}>
                    {wine.characteristics.map((char, charIndex) => (
                      <Chip 
                        key={charIndex} 
                        label={char} 
                        size="small" 
                        variant="outlined"
                      />
                    ))}
                  </Box>

                  <Typography variant="subtitle2" gutterBottom>
                    Alternative Options:
                  </Typography>
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                    {wine.alternativeOptions.map((alt, altIndex) => (
                      <Chip 
                        key={altIndex} 
                        label={alt} 
                        size="small" 
                        variant="outlined"
                      />
                    ))}
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>

        {/* Add Amazon ProductRecommendation component here, after the wine grid */}
        <Divider sx={{ my: 3 }} />
        {parsedRecommendation.wines && (
          <ProductRecommendation 
            wineType={parsedRecommendation.wines[0].type} 
          />
        )}
      </CardContent>
    </Card>
  );
};

export default WineRecommendationCard;