import React from 'react';
import { Box, Typography, Card, CardContent, Grid, Link } from '@mui/material';

// Product data structure with SiteStripe links
const wineProducts = {
  red: [
    {
      title: "Whiskey Decanter Globe Set with 2 Etched Whiskey Glasses",
      link: "https://www.amazon.com/Whiskey-Decanter-Globe-Etched-Glasses/dp/B077Y2SJML?crid=3O1P2BH0I9KDE&dib=eyJ2IjoiMSJ9.tI7y75sp1CqIp07BbmiHnkT_gUMyg_RlONBUV9lQgPrVGmhRdQ9OqUSU3dBeqUNQLF12dJjrEsVeBUCIIb5tQs9JJbMQOk6hzH_WN1LXmeCsxBBAt6EnxAhThD2UI25EWVcoGwjj7irfpB6enJxwI9A-u1COcPOceee6zCUugfYhqaS1T5PyTV8Q6wh0ucirv-uq37UhAL5pojtrjeD-DZeGr7JyuaULkISIvTWdkUwvTW6FKj8Ap_ONrDLGMsVc8Bv7vyhSowXbIpiSL3ObVe4BVUhWlzkNJ5rCTDGFv6g.gdrBiRpNm1aBv4bleiZ33pJvz3IFFrMZIJ8lPGa6e8o&dib_tag=se&keywords=decanter&qid=1734662625&s=home-garden&sprefix=decanter%2Cgarden%2C232&sr=1-2-spons&sp_csd=d2lkZ2V0TmFtZT1zcF9hdGY&th=1&linkCode=ll1&tag=aiwinematch-20&linkId=3f3e3f48d497f8b6a0c5a0bbed3259aa&language=en_US&ref_=as_li_ss_tl",
      description: "For Liquor Scotch Bourbon Vodka, Gifts For Men",
      price: "$58.89"
    },
    // Add more red wine related products
  ],
  white: [
    {
      title: "ELIXIR GLASSWARE Wine Glasses Set of 2-14oz - Square Wine Glasses",
      link: "https://www.amazon.com/dp/B07XD31J5B?_encoding=UTF8&pd_rd_i=B07XD31J5B&qid=1734660716&pd_rd_w=hh2jg&content-id=amzn1.sym.8591358d-1345-4efd-9d50-5bd4e69cd942%3Aamzn1.sym.8591358d-1345-4efd-9d50-5bd4e69cd942&pf_rd_p=8591358d-1345-4efd-9d50-5bd4e69cd942&pf_rd_r=BV6Q1JAQ7HYZZXB9A8Y0&pd_rd_wg=SrsfS&pd_rd_r=269ee672-88fa-4f40-a19d-2668db0fe9d2&pd_rd_plhdr=t&th=1&linkCode=ll1&tag=aiwinematch-20&linkId=edca1f56fdcebcf47f7c7ad45b3bcfcb&language=en_US&ref_=as_li_ss_tl",
      description: "Premium Crystal Wine Glasses - THE PERFECT GIFT FOR ANY OCCASION",
      price: "$24.99"
    },
    // Add more white wine related products
  ],
  sparkling: [
    {
      title: "KLOVEO Champagne Stoppers ",
      link: "https://www.amazon.com/Champagne-Stoppers-Professional-Sparkling-Stoppers/dp/B07ZLSH5F1?crid=1Q53SVZ1POQ93&dib=eyJ2IjoiMSJ9.NwggSUz9EE3Yk7Ax4iQEEPz_DUHnFlX8LH1UYk9jISnxba17hfPtMm1Fo-O8kL9mn36gWnLFITrA199F-7uHl4qGfvjHJBt4Q5J4jAymqTyASADU3M3tzJxZc1jvfZ-aciDSRHymeYVK_x4fCr0pfx8LXOEC_hgKtO0Ky54fVTmAcSBsXY6yuE-O5N7DrCe8sN08uGI1G40P5zr2TVVg5N5iq9rZo1CQqqxI9HoEDVbaxqA15oaraFkX9k0bCXl_in_S71yda_KV3pwPSJmmNL9wS5EUTZqdifLo4dmIHyk.dt-RerRzDfv2SCOwvAsw6jb2lTMl7-xi4-DjNKlDSkE&dib_tag=se&keywords=Champagne%2BStoppers&qid=1734663024&s=home-garden&sprefix=champagne%2Bstoppers%2Cgarden%2C241&sr=1-1-spons&sp_csd=d2lkZ2V0TmFtZT1zcF9hdGY&th=1&linkCode=ll1&tag=aiwinematch-20&linkId=0fa9293177ec28f54d8d970942f3b7cd&language=en_US&ref_=as_li_ss_tl",
      description: "Professional Grade WAF Champagne Bottle Stopper",
      price: "$12.99"
    },
    // Add more sparkling wine related products
  ],
  general: [
    {
      title: "Secura Electric Wine Opener",
      link: "https://www.amazon.com/Secura-SWO-3N-Electrical-Bottle-Stainless/dp/B01261VEOG?dib=eyJ2IjoiMSJ9.5n59J3MJ20PlI76NUNjH1blWhsc5F4HjDe-53Di_J8czK3_zEFLVWnNClTbQmmem2i18aR2rN_ntTFOPnfMCTv0CzfCGMozUqgoOY3VX2awFeru7EiVLx2FezxT4YxD3-xdQmCOq8r4LQkORiB5zp9dvrijQr6YzFaVBLP4m_dR-rucqS5WSoSKw_unoI_q0cwNY9N4TlXlQtNYFZM6wFwa19nyPVgTL3Q8i3s1lZEs.f8Cl7_INmQ0BBPLjqVBSHnyrD-4SUmgJlukt7lZWEjI&dib_tag=se&keywords=Wine+Opener+Set&qid=1734663465&sr=8-13&linkCode=ll1&tag=aiwinematch-20&linkId=2813b42e1155ce4d0facebd947348222&language=en_US&ref_=as_li_ss_tl",
      description: "Automatic Electric Wine Bottle Corkscrew Opener with Foil Cutter - Rechargeable",
      price: "$22.99"
    },
    // Add more general wine products
  ]
};

const ProductRecommendation = ({ wineType = 'general' }) => {
  // Get products based on wine type, fallback to general products
  const products = wineProducts[wineType.toLowerCase()] || wineProducts.general;

  return (
    <Box sx={{ mt: 4 }}>
      <Typography variant="h6" gutterBottom sx={{ color: 'primary.main' }}>
        Recommended Wine Accessories
      </Typography>
      <Grid container spacing={2}>
        {products.map((product, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Card sx={{ 
              height: '100%',
              transition: 'transform 0.2s',
              '&:hover': {
                transform: 'scale(1.02)'
              }
            }}>
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  {product.title}
                </Typography>
                <Typography variant="body2" color="text.secondary" gutterBottom>
                  {product.description}
                </Typography>
                <Typography variant="h6" color="primary" gutterBottom>
                  {product.price}
                </Typography>
                <Link
                  href={product.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  sx={{
                    display: 'inline-block',
                    mt: 1,
                    px: 2,
                    py: 1,
                    bgcolor: 'primary.main',
                    color: 'white',
                    textDecoration: 'none',
                    borderRadius: 1,
                    '&:hover': {
                      bgcolor: 'primary.dark'
                    }
                  }}
                >
                  View on Amazon
                </Link>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default ProductRecommendation;