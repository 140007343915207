import React, { useState, useCallback, useRef } from 'react';
import { 
  AppBar, Toolbar, Typography, Container, TextField, Button, Box, ThemeProvider, createTheme,
  CircularProgress, Snackbar, InputAdornment, Autocomplete
} from '@mui/material';
import { getWineRecommendation } from './claude';
import { debounce } from 'lodash';
import WineBarIcon from '@mui/icons-material/WineBar';
import SearchIcon from '@mui/icons-material/Search';
import LocalDiningIcon from '@mui/icons-material/LocalDining';
import { sortedUniqueFoodItems } from './foodItems';
import WineRecommendationCard from './WineRecommendationCard';
import Footer from './Footer';  
import AppShareButtons from './AppShareButtons';

const theme = createTheme({
  typography: {
    fontFamily: 'Garamond, serif',
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
  },
  palette: {
    primary: {
      main: '#8B0000', // dark red
    },
    secondary: {
      main: '#FFF', // white
    },
    loading: {
      main: 'rgba(255, 255, 255, 0.7)', // light color for loading indicator
    },
  },
});

const WineStore = () => {
  const [userInput, setUserInput] = useState('');
  const [recommendation, setRecommendation] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const cacheRef = useRef({});

  const getRecommendation = useCallback(async (input) => {
    const trimmedInput = input.replace(/\s+/g, ' ').trim();
    
    if (cacheRef.current[trimmedInput]) {
      setRecommendation(cacheRef.current[trimmedInput]);
      return;
    }

    try {
      setIsLoading(true);
      const result = await getWineRecommendation(trimmedInput);
      setRecommendation(result);
      cacheRef.current[trimmedInput] = result;
    } catch (error) {
      console.error('Error getting recommendation:', error);
      setError('Sorry, there was an error getting a recommendation. Please try again.');
    } finally {
      setIsLoading(false);
    }
  }, []);

  const debouncedGetRecommendation = useCallback(
    debounce((input) => getRecommendation(input), 300),
    [getRecommendation]
  );

  const handleInputChange = (event, newInputValue) => {
    setUserInput(newInputValue);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const trimmedInput = userInput.trim();
    if (trimmedInput.length > 2) {
      debouncedGetRecommendation(trimmedInput);
    }
  };

  const schemaMarkup = {
    "@context": "https://schema.org",
    "@type": "WebApplication",
    "name": "AI Wine Match",
    "description": "An AI-powered tool to find the perfect wine pairing for any meal.",
    "url": "https://www.aiwinematch.com",
    "applicationCategory": "Food & Drink",
    "operatingSystem": "All",
    "offers": {
      "@type": "Offer",
      "price": "0",
      "priceCurrency": "USD"
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ 
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column', 
        background: 'linear-gradient(rgba(70, 20, 20, 0.7), rgba(70, 20, 20, 0.7)), url("/api/placeholder/1200/600")',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        position: 'relative'  
      }}>
        <AppBar position="static" color="transparent" elevation={0}>
          <Toolbar>
            <WineBarIcon sx={{ mr: 2, color: 'white' }} />
            <Typography variant="h6" component="div" sx={{ flexGrow: 1, color: 'white' }}>
              AI Wine Match
            </Typography>
          </Toolbar>
        </AppBar>

        <Container maxWidth="md" sx={{ py: 6, textAlign: 'center' }}>
          <Typography variant="h2" component="h1" sx={{ 
            color: 'white', 
            mb: 2, 
            fontSize: { xs: '3rem', md: '4rem' }, 
            fontWeight: 'normal',
            textTransform: 'uppercase',
            letterSpacing: '2px'
          }}>
            Uncork Perfection
          </Typography>
          <Typography variant="h5" sx={{ 
            color: 'white', 
            mb: 4,
            fontStyle: 'italic'
          }}>
            Elevate Your Dining with Expertly Curated Wine Selections, Tailored for Any Language and Locale
          </Typography>
          
          <Box 
            component="form" 
            onSubmit={handleSubmit} 
            sx={{ 
              maxWidth: 800,
              mx: 'auto',
              display: 'flex',
              flexDirection: { xs: 'column', md: 'row' },
              gap: { xs: 2, md: 1 },
              alignItems: 'flex-start',
              justifyContent: 'center'
            }}
          >
            <Box sx={{ flexGrow: 1, width: '100%' }}>
              <Autocomplete
                freeSolo
                options={sortedUniqueFoodItems}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Describe your meal or ask about a pairing..."
                    fullWidth
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <InputAdornment position="start">
                          <LocalDiningIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
                value={userInput}
                onChange={(event, newValue) => {
                  setUserInput(newValue || '');
                }}
                onInputChange={(event, newInputValue) => {
                  setUserInput(newInputValue);
                }}
                sx={{ 
                  '& .MuiOutlinedInput-root': {
                    backgroundColor: 'white',
                    borderRadius: 1,
                    height: '56px', // Match button height
                    '& fieldset': {
                      borderColor: 'transparent',
                    },
                    '&:hover fieldset': {
                      borderColor: 'transparent',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: 'transparent',
                    },
                  },
                  '& .MuiInputBase-input': {
                    fontSize: '1.2rem',
                  },
                }}
              />   
            </Box>
            <Button 
              variant="contained" 
              type="submit" 
              color="secondary"
              startIcon={<SearchIcon />}
              sx={{ 
                color: theme.palette.primary.main,
                fontWeight: 'bold',
                padding: '15px 32px',
                fontSize: '1.2rem',
                height: '56px', // Match input height
                borderRadius: '30px',
                textTransform: 'uppercase',
                letterSpacing: '1px',
                whiteSpace: 'nowrap',
                minWidth: 'fit-content',
                transition: 'all 0.3s ease',
                '&:hover': {
                  backgroundColor: '#f0f0f0',
                  boxShadow: '0 5px 15px rgba(0,0,0,0.3)',
                },
                '&:disabled': {
                  backgroundColor: 'rgba(255, 255, 255, 0.5)',
                  color: 'rgba(139, 0, 0, 0.5)',
                }
              }}
              disabled={isLoading || userInput.length <= 2}
            >
              Find Your Pairing
            </Button>
          </Box>
        </Container>

        {isLoading && (
          <Box sx={{ display: 'flex', justifyContent: 'center', my: 2 }}>
            <CircularProgress 
              sx={{ 
                color: theme => theme.palette.loading.main,
              }} 
            />
          </Box>
        )}

        {recommendation && !isLoading && (
          <Container maxWidth="md" sx={{ pb: 4, pt: 2 }}>
            <WineRecommendationCard recommendation={recommendation} />
          </Container>
        )}

        <Snackbar
          open={!!error}
          autoHideDuration={6000}
          onClose={() => setError('')}
          message={error}
        />
        <AppShareButtons />
        <Footer /> 
      </Box>
    </ThemeProvider>
  );
};

export default WineStore;